import gql from "graphql-tag"

/* ========== Other News ======== */
const OtherNews = gql`
query OtherNews ($id: ID!) {
    newsEvents (sort:"News_Published_Date:desc",limit:3,where:{id_ne: $id}){
      id
      Title
      URL
      Tile_Image {
        url
        alternativeText
      } 
      imagetransforms        
    }
}`

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, where:{search_type:$status, department:$department, publish:true}) {
        id
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        crm_negotiator_id
        bedroom
        reception
        bathroom
        description
    }

}`


/* ========== Property Detail For Booking ======== */

const GetPropertyBooking = gql`
query GetPropertyOffice($id: String!) {
  properties (where:{id:$id}) {
    id
    slug
    display_address
    search_type
    department
    title
    images
    bedroom
    bathroom
    office_crm_id
    price
    price_qualifier
  }
}`

/* ========== Property Area Guide ======== */
const AreaGuideDetail = gql`
query AreaGuideDetail($crm_area_id: String!) {
    areaguides (where: {area_ids: {crm_id: $crm_area_id}}) {
      id
      Areaguide_Title
      URL
      Areaguide_Intro
      Postcode_Mapping
      Banner_Image {
        url
        alternativeText
      }
      imagetransforms
  }
}`

/* ========== Property Area Guide ======== */
const GetAllReviews = gql`
query googleReviewReviews {
  googleReviewReviews {
    id
    starRating
    comment
    createTime
    reviewer
    updateTime
  }
}`



export const Queries = {
  OtherNews,
  NoResultProperties,
  GetPropertyBooking,
  AreaGuideDetail,
  GetAllReviews
};


