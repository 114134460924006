import { useQuery} from '@apollo/client';
import { Queries } from "./common_queries";

export const OtherNews = (id) => {
    return useQuery(Queries.OtherNews,{
        variables: {id: id}
    });
}

export const NoResultProperties = (status, department) => {
    return useQuery(Queries.NoResultProperties, {
        variables: {status: status, department: department}
    });
}

export const GetPropertyBooking = (id) => {
    return useQuery(Queries.GetPropertyBooking,{
        variables: {id: id}
    });
}

export const AreaGuideDetail = (areaCrmId) => {
    return useQuery(Queries.AreaGuideDetail,{
        variables: {crm_area_id: areaCrmId}
    });
}
export const GetAllReviews = () => {
    return useQuery(Queries.GetAllReviews);
}